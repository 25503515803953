import { ROLES } from '@/model/shared/roles';
import { CONTEXTS } from '@/model/shared/contexts';

const basePath = CONTEXTS.DASHBOARD_SSP.baseUrl;
const contextSecure = CONTEXTS.DASHBOARD_SSP.id;

export default {
  path: 'comments',
  name: `${basePath}-platforms-deals-comments`,
  component: () =>
    import(
      /* webpackChunkName: "ds-deal-comments" */ '@/components/organisms/modules/dashboardSSP/platformsDeal/form/dealComments/DealComments'
    ),
  meta: {
    requiresAuth: true,
    title: `Platform's Deals {deal}`,
    contextSecure,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.CLIENT.id, ROLES.SELLER.id],
    loadingIcon: true,
  },
  props: true,
};
