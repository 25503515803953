var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("asterix-application-layout", {
    scopedSlots: _vm._u([
      {
        key: "sidebar",
        fn: function () {
          return [
            _c(
              "asterix-sidebar",
              {
                model: {
                  value: _vm.isSidebarOpen,
                  callback: function ($$v) {
                    _vm.isSidebarOpen = $$v
                  },
                  expression: "isSidebarOpen",
                },
              },
              [
                _c(
                  "sun-sidebar-group",
                  { staticClass: "mt-8" },
                  [
                    _c(
                      "sun-sidebar-nav-item",
                      {
                        attrs: { to: _vm.analytics },
                        scopedSlots: _vm._u([
                          {
                            key: "icon",
                            fn: function () {
                              return [
                                _c("analytics-svg", { staticClass: "w-4" }),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "children",
                            fn: function () {
                              return [
                                _c(
                                  "sun-sidebar-nav-item",
                                  { attrs: { to: _vm.economics } },
                                  [_vm._v("Economics")]
                                ),
                                _c(
                                  "sun-sidebar-nav-item",
                                  { attrs: { to: _vm.openMarket } },
                                  [_vm._v("Open Market")]
                                ),
                                _c(
                                  "sun-sidebar-nav-item",
                                  { attrs: { to: _vm.deals } },
                                  [_vm._v("Deals Web")]
                                ),
                                _c(
                                  "sun-sidebar-nav-item",
                                  { attrs: { to: _vm.dealsOtt } },
                                  [_vm._v("Deals Ott")]
                                ),
                                _c(
                                  "sun-sidebar-nav-item",
                                  { attrs: { to: _vm.markets } },
                                  [_vm._v("Markets")]
                                ),
                                _c(
                                  "sun-sidebar-nav-item",
                                  { attrs: { to: _vm.skin } },
                                  [_vm._v("Skin + Ignite")]
                                ),
                                _c(
                                  "sun-sidebar-nav-item",
                                  { attrs: { to: _vm.others } },
                                  [_vm._v("Others")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [_vm._v(" Analytics ")]
                    ),
                    _c(
                      "sun-sidebar-nav-item",
                      {
                        attrs: { to: _vm.campaignManager },
                        scopedSlots: _vm._u([
                          {
                            key: "icon",
                            fn: function () {
                              return [
                                _c("category-svg", { staticClass: "w-4" }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [
                        _c("span", { staticClass: "font-bold" }, [
                          _vm._v("Campaign Manager"),
                        ]),
                      ]
                    ),
                    _c(
                      "sun-sidebar-nav-item",
                      {
                        attrs: { to: _vm.platforms },
                        scopedSlots: _vm._u([
                          {
                            key: "icon",
                            fn: function () {
                              return [
                                _c("platform-svg", { staticClass: "w-4" }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [_vm._v(" Platforms ")]
                    ),
                    _c(
                      "sun-sidebar-nav-item",
                      {
                        attrs: { to: _vm.admin },
                        scopedSlots: _vm._u([
                          {
                            key: "icon",
                            fn: function () {
                              return [_c("admin-svg", { staticClass: "w-4" })]
                            },
                            proxy: true,
                          },
                          {
                            key: "children",
                            fn: function () {
                              return [
                                _c(
                                  "sun-sidebar-nav-item",
                                  { attrs: { to: _vm.user } },
                                  [_vm._v("Users")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [_vm._v(" Admin ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "header",
        fn: function () {
          return [
            _c("asterix-topbar", {
              staticClass: "z-20",
              on: {
                change: function ($event) {
                  _vm.isSidebarOpen = !_vm.isSidebarOpen
                },
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }