<template>
  <asterix-application-layout>
    <template #sidebar>
      <asterix-sidebar v-model="isSidebarOpen">
        <sun-sidebar-group class="mt-8">
          <sun-sidebar-nav-item :to="analytics">
            <template #icon><analytics-svg class="w-4" /></template>
            Analytics
            <template #children>
              <sun-sidebar-nav-item :to="economics">Economics</sun-sidebar-nav-item>
              <sun-sidebar-nav-item :to="openMarket">Open Market</sun-sidebar-nav-item>
              <sun-sidebar-nav-item :to="deals">Deals Web</sun-sidebar-nav-item>
              <sun-sidebar-nav-item :to="dealsOtt">Deals Ott</sun-sidebar-nav-item>
              <sun-sidebar-nav-item :to="markets">Markets</sun-sidebar-nav-item>
              <sun-sidebar-nav-item :to="skin">Skin + Ignite</sun-sidebar-nav-item>
              <sun-sidebar-nav-item :to="others">Others</sun-sidebar-nav-item>
            </template>
          </sun-sidebar-nav-item>

          <sun-sidebar-nav-item :to="campaignManager">
            <template #icon><category-svg class="w-4" /></template>
            <span class="font-bold">Campaign Manager</span>
          </sun-sidebar-nav-item>

          <sun-sidebar-nav-item :to="platforms">
            <template #icon><platform-svg class="w-4" /></template>
            Platforms
          </sun-sidebar-nav-item>
          <sun-sidebar-nav-item :to="admin">
            <template #icon><admin-svg class="w-4" /></template>
            Admin
            <template #children>
              <sun-sidebar-nav-item :to="user">Users</sun-sidebar-nav-item>
            </template>
          </sun-sidebar-nav-item>
          <!-- <sun-sidebar-nav-item :to="supply">
            <template #icon><demand-svg class="w-4" /></template>
            Supply
            <template #children>
              <sun-sidebar-nav-item :to="platformsDeals">Platform's Deals</sun-sidebar-nav-item>
              <sun-sidebar-nav-item :to="salesforcesDeals">Salesforce's Deals</sun-sidebar-nav-item>
            </template>
          </sun-sidebar-nav-item> -->
        </sun-sidebar-group>
      </asterix-sidebar>
    </template>

    <template #header>
      <asterix-topbar class="z-20" @change="isSidebarOpen = !isSidebarOpen" />
    </template>
  </asterix-application-layout>
</template>

<script>
import AsterixApplicationLayout from '@/components/templates/AsterixApplicationLayout';
import AsterixSidebar from '@/components/molecules/shared/AsterixSidebar/AsterixSidebar';
import AsterixTopbar from '@/components/molecules/shared/AsterixTopbar';
import SunSidebarNavItem from '@/components/molecules/SunSidebarNavItem';
import AdminSvg from '@/components/icons/AdminSvg';
import { campaignManager } from '@/router/private/modules/dashboardSSP/campaignManager';
// import DemandSvg from '@/components/icons/DemandSvg';
import PlatformSvg from '@/components/icons/PlatformSvg';
import { supply } from '@/router/private/modules/dashboardSSP/supply';
import { platformsDeals } from '@/router/private/modules/dashboardSSP/supply/platformsDeals';
import { salesforcesDeals } from '@/router/private/modules/dashboardSSP/supply//salesforcesDeals';
import { admin } from '@/router/private/modules/dashboardSSP/admin';
import { platforms } from '@/router/private/modules/dashboardSSP/platforms';
import { list as user } from '@/router/private/modules/dashboardSSP/admin/users/list';
import { analytics } from '@/router/private/modules/dashboardSSP/analytics';
import { economics } from '@/router/private/modules/dashboardSSP/analytics/economics';
import { deals } from '@/router/private/modules/dashboardSSP/analytics/deals';
import { openMarket } from '@/router/private/modules/dashboardSSP/analytics/openMarket';
import { dealsOtt } from '@/router/private/modules/dashboardSSP/analytics/dealsOtt';
import { markets } from '@/router/private/modules/dashboardSSP/analytics/markets';
import { skin } from '@/router/private/modules/dashboardSSP/analytics/skin';
import { others } from '@/router/private/modules/dashboardSSP/analytics/others';
import AnalyticsSvg from '@/components/icons/AnalyticsSvg';
import CategorySvg from '@/components/icons/CategorySvg.vue';

export default {
  name: 'DashboardSSPIndex',
  components: {
    CategorySvg,
    AsterixApplicationLayout,
    AsterixSidebar,
    AsterixTopbar,
    SunSidebarNavItem,
    // DemandSvg,
    PlatformSvg,
    AdminSvg,
    AnalyticsSvg,
  },
  data: () => ({
    isSidebarOpen: true,
    admin,
    analytics,
    deals,
    openMarket,
    economics,
    platforms,
    platformsDeals,
    salesforcesDeals,
    supply,
    user,
    dealsOtt,
    markets,
    skin,
    others,
    campaignManager,
  }),
};
</script>

<style scoped>
:deep(.body-layout) {
  padding-left: 1rem;
  padding-right: 1rem;
}
@media (min-width: 768px) {
  :deep(#layout) {
    padding-left: 8rem;
  }
  :deep(#sidebar) {
    width: 8rem;
  }
}
:deep(.sidebar-item-content div) {
  padding-left: 3px;
  padding-right: 3px;
}
:deep(.sidebar-item-children .sidebar-item .sidebar-item-content) {
  padding-left: 0.3rem;
}
</style>
