var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 17 18" } },
    [
      _c("g", { attrs: { id: "Capa_2", "data-name": "Capa 2" } }, [
        _c("g", { attrs: { id: "Capa_1-2", "data-name": "Capa 1" } }, [
          _c("g", { attrs: { id: "HR8wHc.tif" } }, [
            _c("path", {
              staticClass: "fill-current",
              attrs: {
                d: "M9,2a3.17,3.17,0,0,1,.37.17l5.29,3.32c.43.27.44.64,0,.92-1.68,1-3.38,\n  2.09-5,3.17a1,1,0,0,1-1.23,0C6.7,8.46,5,7.42,3.33,6.37c-.44-.28-.44-.65,0-.92l5.3-3.33Z",
              },
            }),
            _c("path", {
              staticClass: "fill-current",
              attrs: {
                d: "M9,13a2.52,2.52,0,0,1-.29-.12L3.26,9.43a.45.45,0,0,1,\n      0-.82c.24-.15.49-.28.72-.45a.42.42,\n      0,0,1,.55,0C5.76,8.93,7,9.7,8.22,10.46a1.34,1.34,0,0,0,1.53,0l3.7-2.32a.38.38,0,0,1,.49,\n      0c.23.17.49.31.74.46s.39.6,0,.84l-4.61,2.91c-.25.16-.5.33-.76.48A2.52,2.52,0,0,1,9,13Z",
              },
            }),
            _c("path", {
              staticClass: "fill-current",
              attrs: {
                d: "M15,12.12a.66.66,0,0,1-.36.47l-2.76,1.73c-.85.53-1.7,1.06-2.54,1.6a.6.6,0,0,\n      1-.72,0L3.31,\n    12.58c-.43-.26-.42-.61,0-.87.25-.16.5-.31.73-.48a.33.33,0,0,1,.43,0c1.19.76,2.4,1.5,3.59,2.28a1.52,1.52,0,0,0,\n    1.82,0c1.2-.78,2.42-1.53,3.63-2.3a.27.27,0,0,1,.34,0c.29.19.59.36.87.57A1.39,1.39,0,0,1,15,12.12Z",
              },
            }),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }