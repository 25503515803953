var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 18 18" } },
    [
      _c("title", [_vm._v("Category")]),
      _c("g", { attrs: { id: "Capa_2", "data-name": "Capa 2" } }, [
        _c("g", { attrs: { id: "Capa_1-2", "data-name": "Capa 1" } }, [
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M15.35,11.87H14.12s0,0,0-.06V9.09H9.35v-3h1.24a.65.65,0,0,0,\n    .65-.65v-2a.66.66,0,0,0-.65-.66H7.38a.67.67,0,0,0-.66.66v2a.66.66,\n    0,0,0,.66.65H8.57v3H3.87v2.72s0,0,0,.06H2.65a.65.65,0,0,0-.65.65v2a.66.66,\n    0,0,0,.65.66H5.87a.66.66,0,0,0,.65-.66v-2a.65.65,0,0,0-.65-.65H4.64s0,0,\n    0-.06V9.87h8.7v1.94s0,0,0,.06H12.13a.65.65,0,0,0-.65.65v2a.66.66,0,0,0,\n    .65.66h3.22A.66.66,0,0,0,16,14.5v-2A.65.65,0,0,0,15.35,11.87Z",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }