import { salesforcesDeals } from './salesforcesDeals';
import { platformsDeals } from './platformsDeals';
import { CONTEXTS } from '@/model/shared/contexts';
import { ROLES } from '@/model/shared/roles';

const basePath = CONTEXTS.DASHBOARD_SSP.baseUrl;

export const supply = {
  path: 'supply',
  name: `${basePath}-supply`,
  redirect: { name: platformsDeals.name },
  meta: {
    requiresAuth: true,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.SELLER.id, ROLES.CLIENT.id],
  },
  component: {
    render(c) {
      return c('router-view');
    },
  },
  children: [platformsDeals, salesforcesDeals],
  props: true,
};
