import { ROLES } from '@/model/shared/roles';
import { CONTEXTS } from '@/model/shared/contexts';
import comments from '@/router/private/modules/dashboardSSP/supply/platformsDeals/editForm/comments';
import { BreadcrumbItem } from '@/entities/BreadcrumbItem';

const basePath = CONTEXTS.DASHBOARD_SSP.baseUrl;
const contextSecure = CONTEXTS.DASHBOARD_SSP.id;

export const edit = {
  path: ':platformDealId/edit',
  name: `${basePath}-platforms-deals-edit`,
  redirect: { name: comments.name },
  component: () =>
    import(
      /* webpackChunkName: "ds-platforms-deal" */ '@/views/private/modules/dashboardSSP/platformsDeal/PlatformsDealEdit'
    ),
  meta: {
    requiresAuth: true,
    title: `Platform's Deals`,
    contextSecure,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.CLIENT.id, ROLES.SELLER.id],
    breadcrumbs: new BreadcrumbItem('Edit Deal', { name: `${basePath}-platforms-deals-edit` }, {}),
    loadingIcon: true,
  },
  props: true,
  children: [comments],
};
