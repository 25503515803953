import { CONTEXTS } from '@/model/shared/contexts';
import { BreadcrumbItem } from '@/entities/BreadcrumbItem';
import { list } from './list';
import { edit } from './edit';

const basePath = CONTEXTS.DASHBOARD_SSP.baseUrl;

export const salesforcesDeals = {
  path: 'salesforces-deals',
  name: `${basePath}-salesforces-deals`,
  redirect: { name: list.name },
  component: {
    render(c) {
      return c('router-view');
    },
  },
  meta: {
    breadcrumbs: new BreadcrumbItem(`Salesforce's Deals`, { name: list.name }, {}),
  },
  children: [list, edit],
};
