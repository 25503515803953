import { ROLES } from '@/model/shared/roles';
import { CONTEXTS } from '@/model/shared/contexts';
import platformsDeal from '@/router/private/modules/dashboardSSP/supply/salesforcesDeals/editForm/platformsDeal';
import { BreadcrumbItem } from '@/entities/BreadcrumbItem';
import basicInfo from '@/router/private/modules/dashboardSSP/supply/salesforcesDeals/editForm/details';

const basePath = CONTEXTS.DASHBOARD_SSP.baseUrl;
const contextSecure = CONTEXTS.DASHBOARD_SSP.id;

export const edit = {
  path: ':dealId/edit',
  name: `${basePath}-salesforces-deals-edit`,
  redirect: { name: platformsDeal.name },
  component: () =>
    import(
      /* webpackChunkName: "ds-salesforces-deal" */ '@/views/private/modules/dashboardSSP/salesforcesDeals/SalesforcesDealEdit'
    ),
  meta: {
    requiresAuth: true,
    title: `Salesforce's Deals`,
    contextSecure,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.CLIENT.id, ROLES.SELLER.id],
    breadcrumbs: new BreadcrumbItem('Edit Deal', { name: `${basePath}-salesforces-deals-edit` }, {}),
    loadingIcon: true,
  },
  props: true,
  children: [platformsDeal, basicInfo],
};
