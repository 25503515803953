import { ROLES } from '@/model/shared/roles';
import { CONTEXTS } from '@/model/shared/contexts';

const basePath = CONTEXTS.DASHBOARD_SSP.baseUrl;
const contextSecure = CONTEXTS.DASHBOARD_SSP.id;

export const list = {
  path: 'list',
  name: `${basePath}-platforms-deals-list`,
  component: () =>
    import(
      /* webpackChunkName: "ds-platforms-deal" */ '@/views/private/modules/dashboardSSP/platformsDeal/PlatformsDealList'
    ),
  meta: {
    requiresAuth: true,
    title: `Platform's Deals`,
    contextSecure,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.CLIENT.id, ROLES.SELLER.id],
  },
  props: true,
};
